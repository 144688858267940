import React from "react";
import {
  SiteNavigationElementSchema,
  LocalBusinessSchema,
  SiteNavigation,
  HookComponentProvider,
  HoursOfOperation,
  LocationsContext,
  RecaptchaProvider,
  SocialMedia,
  Location,
  Phone,
  Email,
  Map,
  SiteMap,
  CopyrightLabel,
  OutboundLink,
  FishermanBanner,
  DynamicScript,
} from "@bluefin/components";
import { Header, Grid } from "semantic-ui-react";
import { useContext } from "react";
import { graphql, StaticQuery } from "gatsby";

import {
  getComponentFiles,
  filterRegularLinks,
  filterButtonLinks,
} from "../utils/utils";
import "../semantic/semantic.less";
import "../style/WebsiteLayout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            title
            pageType
            url
            navigationPriority
            groupName
            props
          }
        }
        fishermanBusiness {
          name
          logo
          gatsbyLogo {
            childImageSharp {
              gatsbyImageData(layout: FIXED, height: 60)
            }
          }
          socialMedia {
            link
            type
          }
        }
        allFishermanBusinessCategory {
          nodes {
            category
            subCategory
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
          components {
            fastId
            componentIdentifier
            order
            files {
              file
            }
          }
        }
      }
    `}
    render={(data) => {
      const {
        allFishermanBusinessWebsitePage,
        fishermanBusiness,
        allFishermanBusinessCategory,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
      } = data;

      return (
        <React.Fragment>
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://thedonsbsxsp.com"}
          />
          <LocalBusinessSchema
            name={fishermanBusiness.name}
            categories={allFishermanBusinessCategory.nodes}
            url={"https://thedonsbsxsp.com"}
            logo={fishermanBusiness.logo}
            address={allFishermanBusinessLocation.nodes}
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
          />
          <div className={"site-container"}>
            <div>
              <SiteNavigation fullScreen={true}>
                <SiteNavigation.Menu position={"left"}>
                  <SiteNavigation.Logo
                    title={<Header as={"h3"}>{fishermanBusiness.name}</Header>}
                    src={fishermanBusiness.gatsbyLogo.childImageSharp}
                    position={"left"}
                  />
                </SiteNavigation.Menu>
                <SiteNavigation.Menu
                  className={"site-navigation-menu centered"}
                >
                  <SiteNavigation.Links
                    links={filterRegularLinks({
                      links: allFishermanBusinessWebsitePage.nodes,
                    })}
                  />
                </SiteNavigation.Menu>
                <SiteNavigation.Menu position={"right"}>
                  <SiteNavigation.Item>
                    <HookComponentProvider
                      hook={useContext}
                      component={
                        <HoursOfOperation
                          displayOption={"dailyWithPopup"}
                          displayOpenClosedStatus={true}
                        />
                      }
                      hookToPropsMap={{
                        hours: "activeLocation.hours",
                        timezone: "activeLocation.timezone",
                      }}
                      hookParams={[LocationsContext]}
                      onlyRenderOnClientSide={true}
                    />
                  </SiteNavigation.Item>
                  <SiteNavigation.Links
                    links={filterButtonLinks({
                      links: allFishermanBusinessWebsitePage.nodes,
                    })}
                  />
                </SiteNavigation.Menu>
              </SiteNavigation>
            </div>
            <RecaptchaProvider>
              <div className={"page-container"}>{children}</div>
              <footer>
                <Grid stackable={true}>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <Grid stackable={true}>
                        <Grid.Row columns={3}>
                          <Grid.Column>
                            <SocialMedia
                              socialMediaValues={fishermanBusiness.socialMedia}
                              buttonType={"rect"}
                              centerHeader={false}
                              colors={"secondary"}
                              groupButtons={false}
                              groupVertically={false}
                              inverted={true}
                              labelPosition={"free"}
                              showLabels={false}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={3}>
                          <Grid.Column>
                            <HookComponentProvider
                              hook={useContext}
                              component={<Location businessName={"Address"} />}
                              hookToPropsMap={{
                                "address.street": "activeLocation.street",
                                "address.city": "activeLocation.city",
                                "address.state": "activeLocation.state",
                                "address.zip": "activeLocation.zipCode",
                              }}
                              requiredProps={[
                                [
                                  "address.street",
                                  "address.city",
                                  "address.state",
                                  "address.zipCode",
                                ],
                              ]}
                              hookParams={[LocationsContext]}
                              onlyRenderOnClientSide={true}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <address>
                              <HookComponentProvider
                                hook={useContext}
                                component={
                                  <div>
                                    <strong>Contact</strong>
                                  </div>
                                }
                                hookToPropsMap={{
                                  phone: "activeLocation.phoneNumber",
                                  email: "activeLocation.email",
                                }}
                                requiredProps={[["phone", "email"]]}
                                hookParams={[LocationsContext]}
                              />
                              <HookComponentProvider
                                hook={useContext}
                                component={Phone}
                                hookToPropsMap={{
                                  phone: "activeLocation.phoneNumber",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                              <HookComponentProvider
                                hook={useContext}
                                component={Email}
                                hookToPropsMap={{
                                  email: "activeLocation.email",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                            </address>
                          </Grid.Column>
                          <Grid.Column>
                            <strong>Hours of Operation</strong>
                            <HookComponentProvider
                              hook={useContext}
                              component={
                                <HoursOfOperation
                                  displayOption={"dailyWithPopup"}
                                  displayOpenClosedStatus={true}
                                  startWithToday={false}
                                  position={"top left"}
                                />
                              }
                              hookToPropsMap={{
                                hours: "activeLocation.hours",
                                timezone: "activeLocation.timezone",
                              }}
                              hookParams={[LocationsContext]}
                              onlyRenderOnClientSide={true}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <HookComponentProvider
                        hook={useContext}
                        component={Map}
                        hookToPropsMap={{
                          "address.street": "activeLocation.street",
                          "address.city": "activeLocation.city",
                          "address.state": "activeLocation.state",
                          "address.zip": "activeLocation.zipCode",
                        }}
                        hookParams={[LocationsContext]}
                        onlyRenderOnClientSide={true}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign={"center"}>
                      <SiteMap
                        links={allFishermanBusinessWebsitePage.nodes}
                        horizontal={true}
                        bulleted={false}
                      />
                      <CopyrightLabel
                        company={fishermanBusiness.name}
                        phrase={"All Rights Reserved"}
                        privacyPolicyLink={
                          <OutboundLink
                            to={
                              "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                            }
                            label={"Privacy Policy"}
                            eventLabel={"copyright-privacy-policy"}
                          />
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </footer>
            </RecaptchaProvider>
          </div>
          <FishermanBanner />
          <DynamicScript
            type={"text/javascript"}
            delay={500}
            body={
              "! function(e, t) {var i = e.createElement('script'),sa = 'setAttribute';i.src = 'https://widget.getsquire.com/widget.js?' + Date.now(), i[sa]('defer', ''), i[sa]('type', 'text/javascript'), i[sa]('brand', t.brand), i[sa]('x-squire-inline-enabled', t.inline || !1), e.head.appendChild(i)}(document, {brand: '5623e2a8-a3a0-4f34-979c-4f5a62bf4163',inline: true});"
            }
          />
        </React.Fragment>
      );
    }}
  />
);
